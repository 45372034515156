<template>
  <div>
    <div class="wrap">
      <div class="title">
        <div>欢迎注册企政牛OA系统</div>
        <!-- <div>请选择使用方式</div> -->
      </div>
      <div class="box">
        <div class="btn" @click="createOa" v-if="!this.isInvite">
          <span class="text">创建自己的OA系统</span>
        </div>
        <div class="block" v-else>
          <div class="tit">加入已有系统</div>
          <div class="input-wrap">
            <input type="text" v-model="yqm" class="input" placeholder="请输入用户姓名">
            <div class="input-btn" @click="joinSystem">
              <img src="@/assets/icons/in.png" alt="" class="input-icon">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import service from '@/service/service';
import { message } from 'ant-design-vue';
import { mapActions, mapState } from 'vuex';

export default {
  components: {},
  data() {
    return {
      yqm: "",
      isInvite:false,
    }
  },
  watch:{
    userinfo:{
      handler(nowVal) {
        if (nowVal) {
          service.check_temp_user({phone:nowVal.phone}).then(res=>{
            if (res.data) {
              this.isInvite = true;
            }
            // console.log('hello');
          })
        }
      },
      immediate:true, 
    }
  },
  computed: {
    ...mapState(['userinfo']),
  },
  methods: {
    ...mapActions(['getUserinfo']),
    async createOa() {
      let key = 'createLoading'
      message.loading({ content: '操作中...', key });
      let { code, data } = await service.create();
      message.destroy(key);
      if (code && code == '1') {
        let { data: token } = await service.get_token({ userid: this.userinfo.userid });
        window.sessionStorage.setItem('token', token);
        this.$router.push('/platform/doneinfo?id=' + data.company_id)
      }
    },
    async joinSystem() {
      if (!this.yqm) {
        message.error('请先输入邀请码!');
        return;
      }
      let key = 'joinLoading';
      message.loading({ content: '操作中...', key });
      const { code, msg } = await service.join_system({ name: this.yqm, phone: this.userinfo?.phone });
      if (code && code == '1') {
        message.success({ content: msg || '加入成功', key })
        this.getUserinfo();
      }
      else {
        message.error({ content: msg || '加入失败', key })
      }
      if (code && code == '1') {
        setTimeout(() => {
          this.$router.push('/work/home')
        }, 500)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.wrap {
  padding-top: 100px;

  .title {
    font-size: 60px;
    color: #999;
    font-weight: 100;
    text-align: center;
    // font-weight:lighter;
    margin-bottom: 70px;
  }

  .box {
    width: 510px;
    margin: auto;

    .btn {
      margin-bottom: 36px;
      height: 96px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      background: @primary-color;
      color: #fff;
      font-size: 40px;
      font-weight: 100;
      justify-content: center;
      cursor: pointer;
    }

    .block {
      border-radius: 5px;
      background: #fff;
      padding: 24px 32px;
      padding-bottom: 74px;

      .tit {
        color: @primary-color;
        text-align: center;
        margin-bottom: 24px;
        font-size: 40px;
        font-weight: 100;
      }

      .input-wrap {
        border-radius: 5px;
        border: 1px solid #f5f6f7;
        padding: 2px 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;

        .input {
          border: none;
          padding-left: 16px;
          font-size: 24px;
          outline: none;
          background: none;
          height: 48px;

          &::placeholder {
            color: #ccc;
            font-weight: 100;
          }
        }

        .input-btn {
          padding: 6px 16px;
          border-radius: 5px;
          background: @primary-color;
        }
      }

    }
  }

}
</style>